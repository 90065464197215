<script>
// import Datepicker from "vue3-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';
import Swal from 'sweetalert2';
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Layout,
    PageHeader,
    // Datepicker
  },
  data() {
    return {
      alertData: {},
      alerts: {},
      picked: '',
      title: "Zones sensibles",
      items: [
        {
          text: "Liste",
          href: "/settings",
        },
        {
          text: "Zones sensibles",
          active: true,
        },
      ],

    };
  },
  methods: {
    ...reqMethods,
    searchFilter(e) {
      const searchStr = e.target.value;
      this.alertData = this.alerts.filter((alert) => {
        return (
          alert.name.toLowerCase().includes(searchStr.toLowerCase()) ||
          alert.short_code.toLowerCase().includes(searchStr.toLowerCase())
        );
      });
    },
    dateFilter() {
      const dateSearh = this.picked;
      this.alertData = this.alerts.filter((alert) => {
        return (
          alert.created_at.split('T')[0].includes(dateSearh)
        );
      });
    },
    getDangerAreas() {
      const that = this;
      this.getRequest('/dangerarea/dangerarea/')
        .then(
          function (response) {
            that.alertData = response.data;
            that.alerts = response.data;
          },
          function (error) {
            console.log(error);
          }
        )
    },
    deleteDangerArea(id) {
      // const that = this;
      Swal.fire({
        title: 'supprimer zone',
        text: "Souhaitez-vous supprimer cette zone?",
        imageHeight: 40,
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Oui, supprimer !",
      }).then(async result => {
        if (result.value) {
          const formData = new FormData();
         formData.append('id', id);  
        try {
          const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/dangerarea/dangerarea/`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
          console.log(response);
          this.getDangerAreas();
          Swal.fire('success', "La zone a été supprimer avec succèss!", "success");
        } catch (error) {
          console.log(error);
          Swal.fire({
          title: "Oops...",
          text: "Erreur lors de la suppression de la zone",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
        }
        }
      });
    },
    detailsMessage(message) {
      localStorage.setItem('zoneS', JSON.stringify(message))
      this.$router.push({ path: `/zone-sensible/${message.id}` })
    },
  },
  mounted() {
    this.getDangerAreas();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row justify-content-between">
            <div class="col-md-5">
              <div class="form-group mt-3 mb-0">
                <div class="search-box me-2">
                  <div class="position-relative">
                    <input type="text" class="form-control bg-soft bg-info border-0" placeholder="Rechercher..."
                      @input="searchFilter($event)" />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-md-6 justify-content-end">
              <div class="form-group col-md-4 mt-3 mb-0">
                <input type="date" v-model="picked" class="form-control">
              </div>
              <div class="mt-3 col-md-4 mb-0">
                <button class="btn btn-info" @click="dateFilter()">
                  <i class="bx bx-slider align-middle me-1"></i>
                  Filtrer
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-3">
            <table
              class="table table-hover project-list-table table-nowrap table-centered table-borderless table-striped align-middle">
              <thead class="table-light">
                <tr>
                  <th>Code</th>
                  <th scope="col">Titre</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="treatment in alertData" :key="treatment.id">
                  <td>
                    {{ treatment.short_code }}
                  </td>
                  <td>
                    {{ treatment.name }}
                  </td>
                  <td>
                    {{ treatment.created_at.split('T')[0] }}
                  </td>
                  <td>
                    <router-link @click="detailsMessage(treatment)" to=""
                      class="action-icon text-success me-2">
                      <i class="mdi mdi-eye font-size-18"></i>
                    </router-link>
                    <router-link @click="deleteDangerArea(treatment.id)" to="" class="action-icon text-danger">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

  </Layout>
</template>
